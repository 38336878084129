export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'regionCode',
    label: 'field.region',
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'duration',
    label: 'field.duration',
    hideToggle: true,
  },
  {
    key: 'distance',
    label: 'field.distance',
    hideToggle: true,
  },
  {
    key: 'fromCheckoutTime',
    label: 'field.fromTime',
    type: 'datetime',
    tdClass: 'text-success',
    hideToggle: true,
  },
  {
    key: 'fromAddress',
    label: 'field.fromAddress',
    type: 'map',
    latitudeKey: 'fromLatitude',
    longitudeKey: 'fromLongitude',
  },
  {
    key: 'toCheckinTime',
    label: 'field.toTime',
    type: 'datetime',
    tdClass: 'text-danger',
    hideToggle: true,
  },
  {
    key: 'toAddress',
    label: 'field.toAddress',
    type: 'map',
    latitudeKey: 'toLatitude',
    longitudeKey: 'toLongitude',
  },
];
