export default [
  {
    key: 'regionCode',
    label: 'field.region',
    width: 2000,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 3000,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
  },
  {
    key: 'duration',
    label: 'field.duration',
    width: 2000,
  },
  {
    key: 'distance',
    label: 'field.distance',
  },
  {
    key: 'fromCheckoutTime',
    label: 'field.fromTime',
  },
  {
    key: 'fromAddress',
    label: 'field.fromAddress',
  },
  {
    key: 'fromLatitude',
    label: 'field.fromLatitude',
  },
  {
    key: 'fromLongitude',
    label: 'field.fromLongitude',
  },
  {
    key: 'toCheckinTime',
    label: 'field.toTime',
  },
  {
    key: 'toAddress',
    label: 'field.toAddress',
  },
  {
    key: 'toLatitude',
    label: 'field.toLatitude',
  },
  {
    key: 'toLongitude',
    label: 'field.toLongitude',
  },
];
